<template>
  <section class="init-login-view">
    <h1 v-html="$t('login.title')" />
    <h2 v-html="$t('login.apiName')" />
    <section class="row">
      <p
        v-if="entity"
        class="welcome-message"
        v-html="`${entity.entity} ${$t('login.messageLogin')}`"
      />
      <img class="main-menu-img" :src="getLogo()" />
    </section>
    <section class="complaint-section">
      <section class="track-complaint separation-border">
        <h3 v-html="$t('login.createComplaint')" />
        <AppButton
          title="newComplaint"
          :color="{ color: 'primary', hover: 'secondary' }"
          @click="
            request = {};
            $emit('popup', {
              title: 'initCreateComplaint',
              body: `${$t('modal.initMessage1')}${entity.entity}${$t(
                'modal.initMessage2'
              )}`,
              button: () => callback('new-complaint', '/home/new-complaint'),
            });
          "
        />
      </section>
      <section class="track-complaint">
        <h3 v-html="$t('login.trackComplaint')" />
        <AppInput
          title="login.trackNumber"
          v-model:value="request['trackNumber']"
        />
        <p
          class="link-password"
          v-html="$t('login.forgotPassword')"
          @click="showModalReset = true"
        />
        <AppInput
          title="login.password"
          v-model:type="type"
          v-model:value="request['trackPassword']"
        />
        <AppButton
          title="access"
          @click="
            callback('goTo', `/home/track-complaint/${request['trackNumber']}`)
          "
          :color="{ color: 'primary', hover: 'secondary' }"
        />
      </section>
    </section>
    <AppModal
      :showModal="showModalReset"
      @close="showModalReset = false"
      :width="32"
      :height="25"
    >
      <template #header>
        <h2 v-html="$t('login.reset')" />
      </template>
      <template #body>
        <div v-html="$t('login.resetPasswordComplaint')" />
        <p class="error-password" />
        <AppInput
          title="login.trackNumber"
          v-model:value="request['complaintId']"
        />
        <AppInput
          title="login.password"
          v-model:type="type"
          v-model:value="request['password']"
        />
      </template>
      <template #footer>
        <AppButton
          title="reset"
          @click="callback('reset')"
          :color="{ color: 'primary', hover: 'secondary' }"
        />
        <AppButton
          title="cancel"
          @click="showModalReset = false"
          :color="{ color: 'primary', hover: 'secondary' }"
        />
      </template>
    </AppModal>
    <p class="powered" v-html="$t('login.powered')" />
  </section>
  <Footer />
</template>

<script>
import AppInput from "@/components/common/app-input/input.vue";
import Footer from "@/layouts/Components/Footer.vue";
import { useRouter } from "vue-router";
import { getUserByEntityIdAndRole } from "@/services/user";
import { getComplaintById, resetComplaintPassword } from "@/services/complaint";
import { response } from "@/services/general";
import { computed, onMounted, ref } from "vue";
import AppButton from "@/components/common/app-button/button.vue";
import AppModal from "@/components/common/modal/modal.vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default {
  name: "login",
  components: { AppButton, AppInput, AppModal, Footer },
  props: ["loading"],
  setup(_, { emit }) {
    const store = useStore();
    const router = useRouter();
    const request = ref({});
    const { tm } = useI18n();
    const showModalReset = ref(false);
    let type = ref("password");
    const logo = computed(() => store.getters[`Setting/logoState`]);
    const entity = computed(() => store.getters[`Setting/getEntity`]);

    onMounted(() => {
      request.value = {};
      getUserByEntityIdAndRole({ id: `${process.env.VUE_APP_ENTITY_ID}` });
    });

    async function callback(action, payload) {
      switch (action) {
        case "goTo":
          sessionStorage.setItem("authToken", request.value.trackPassword);
          const status = await getComplaintById({
            complaintId: request.value["trackNumber"],
            password: true,
          });
          response(status, payload, "password", emit);
          break;
        case "reset":
          if (
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(
              request.value.password
            )
          ) {
            const status = await resetComplaintPassword({
              id: request.value["complaintId"],
              password: request.value["password"],
            });
            showModalReset.value = false;
            response(status, null, "trackNumber", emit);
          } else {
            var p = document.getElementsByClassName("error-password")[0];
            p.innerHTML = tm(`app.passwordPolicies`);
            setTimeout(() => {
              p.innerHTML = "";
            }, 4000);
          }
          break;
        case "new-complaint":
          router.push(payload);
          break;
      }
    }
    function getLogo() {
      var img = require.context("../../assets/images/");
      return img(`./${logo.value}`);
    }

    return {
      showModalReset,
      request,
      entity,
      type,
      getLogo,
      callback,
    };
  },
};
</script>

<style lang="scss">
@import "../../assets/styles/styles.scss";

.row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  row-gap: 3vh;
  column-gap: 5vw;
}

.init-login-view {
  display: grid;
  grid-template-areas:
    "title     title     title"
    "subtitle  subtitle  subtitle"
    "message   login     login"
    "complaint complaint complaint"
    "copyright copyright copyright";
  align-content: flex-start;
  row-gap: 3vh;
  width: 100%;

  h1 {
    grid-area: title;
    color: var(--mq-white);
  }

  h2 {
    grid-area: subtitle;
    font-size: 4.5vh;
    margin: 5vh 0 0vh 3vw !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    color: var(--mq-white);
  }

  .welcome-message {
    grid-area: message;
    color: var(--mq-primary);
    text-align: left;
    font-size: 2.25vh;
    padding: 3vh 2vw 3vh 4vw;
    max-width: 45vw;
    background-color: white;
    cursor: default;
  }

  .main-menu-img {
    grid-area: login;
    width: 350px;
    margin: auto auto;
  }

  .complaint-section {
    grid-area: complaint;
    background-color: white;
    display: flex;
    flex-flow: row wrap;
    max-width: 55vw;
    padding: 3vh 3vw;
    margin: 0 auto 0 auto;

    @media all and (max-width: 1200px) {
      max-width: 70vw;
    }

    .track-complaint {
      position: relative;
      display: flex;
      flex-direction: column;
      flex-basis: 20rem;
      flex-grow: 1;
      padding: 3vh 2vw;
      text-align: left;
      row-gap: 2vh;
      align-items: center;

      .link-password {
        align-self: flex-end;
        text-decoration: underline;
        color: var(--mq-primary);
        cursor: pointer;
        font-size: 1.5vh;
        line-height: 1px;
        margin-top: -1vh;
        margin-bottom: -1vh;
      }

      h3 {
        width: 100%;
      }
    }

    .separation-border {
      border-right: 1px solid black;

      @media all and (max-width: 1306px) {
        border-bottom: 1px solid black;
        border-right: none;
      }
    }
  }

  .error-password {
    font-size: 1.6vh;
    text-align: left;
    margin: 0;
    padding: 0;
  }

  .powered {
    grid-area: copyright;
    text-align: center;
    font-size: 1.5vh;
    margin: 2.45vh 0;
  }
}
</style>
