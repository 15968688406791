<template>
  <div>
    <div
      :style="{
        display: 'flex',
        justifyContent: 'space-between',
      }"
    >
      <label class="form-checkbox-label">
        <input
          type="checkbox"
          class="form-checkbox-input"
          :checked="value"
          @change="
            $emit('update:value', { privacyPolicies: $event.target.checked })
          "
        />
        <span class="form-checkbox-text"></span>
      </label>
      <span
        class="link-password"
        @click="
          $emit('update:value', { privacyPolicies: !value })
        "
        v-html="label + $t('acceptPolicy.link')"
      ></span>
    </div>
    
    <p
      class="privacy-pol"
    >
      <span
        v-html="transformEmail($t('acceptPolicy.first'))"
      />
      <a
        target="_blank"
        v-bind:href="policyURI"
        class="link-privacy-pol"
        v-html="$t('acceptPolicy.second')"
      />
    </p>
  </div>
  <section
    v-if="error"
    style="
      color: var(--mq-secondary);
      display: inline-flex;
      margin: 0.5vh 0.25vw;
    "
  >
    <Icon type="alert" :width="1" :color="'secondary'" />
    {{ $t("form.verify") }}
  </section>
</template>

<script>
import Icon from "../app-icon/icon.vue";
import AppModal from "../modal/modal.vue";
import AppButton from "../app-button/button.vue";
import { ref } from "vue";
import { i18n } from "../../../main";

const policyS3URI = 'https://cdd-bucket.s3.eu-west-1.amazonaws.com/pro';
// const policyS3URI = 'https://cdd-bucket.s3.eu-west-1.amazonaws.com/dev';
const policyExtension = '.pdf';

export default {
  name: "CheckboxField",
  components: {
    Icon,
    AppModal,
    AppButton,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: { default: undefined },
    error: {
      default: false,
    },
  },
  setup(_, { emit }) {
    const entityNameLegal = process.env.VUE_APP_ENTITY_NAME_LEGAL;
    const entityNameAbbreviated = process.env.VUE_APP_ENTITY_NAME_ABBREVIATED;
    const entityDpoEmail = process.env.VUE_APP_ENTITY_DPO_EMAIL;
    const currentLang = i18n.global.locale.value;

    function transform(data) {
      return data.replace(/__NOMBRE__/g, entityNameLegal);
    }

    function transformEmail(data) {
      data = data.replace(/__NOMBRE__/g, entityNameLegal);
      data = data.replace(/__EMAIL__/g, entityDpoEmail);
      return data;
    }

    return {
      policyURI: policyS3URI + '/' + entityNameAbbreviated + '/' + currentLang + policyExtension,
      transform,
      transformEmail,
    };
  },
};
</script>

<style lang="scss" scoped>
.form-checkbox-label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.form-checkbox-description {
  font-size: 15px;
}

/* Hide the browser's default checkbox */
.form-checkbox-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.form-checkbox-text {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #4f2d7f;
}

.form-checkbox-input.is-invalid + .form-checkbox-text::before {
  border-color: #de002e;
}
.form-checkbox-input:focus + .form-checkbox-text::before {
  box-shadow: 0 0 0 0.125rem #fff, 0 0 0 0.25rem #b9abcc;
}

/* When the checkbox is checked, add a blue background */
.form-checkbox-label input:checked ~ .form-checkbox-text {
  background-color: "transparent";
  border-width: 3px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.form-checkbox-text:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.form-checkbox-label input:checked ~ .form-checkbox-text:after {
  display: block;
}

/* Style the checkmark/indicator */
.form-checkbox-label .form-checkbox-text:after {
  left: 4px;
  width: 7px;
  height: 11px;
  border: solid #4f2d7f;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.form-checkbox-input:disabled + .form-checkbox-text {
  color: #999;
  pointer-events: none;
  cursor: context-menu;
}
.form-checkbox-input:disabled + .form-checkbox-text::before {
  border-color: #cbc4bc;
}

.form-checkbox-label:hover
  .form-checkbox-input:not(:disabled):not(:focus)
  + .form-checkbox-text {
  color: #de002e;
}
.form-checkbox-label:hover
  .form-checkbox-input:not(:disabled):not(:focus)
  + .form-checkbox-text::before {
  border-color: #de002e;
}

/* Customize the label (the wrapper) */
.link-password {
  font-weight: bold;
  text-decoration: underline;
  color: var(--mq-terciary);
  cursor: pointer;
  font-size: 15px;
}

.privacy-pol {
  padding-top: 1rem;
  max-width: 25vw;
}

.link-privacy-pol {
  font-weight: bold;
  text-decoration: underline;
  color: var(--mq-terciary);
  cursor: pointer;
  font-size: 15px;
}
</style>
