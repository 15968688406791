import Service from "./services";
import constantURL from "../config/constant";
import moment from "moment";
import { computed } from "vue";
import { i18n } from "../main";
import store from "../store/index.js";
require('moment-timezone');
var services = new Service();
// Add managers to specific complaint.
export const assignManagers = async ({ users, complaintId }) => {
  let response;
  try {
    let mappedUsers = users.map((el) => {
      return { email: el.email };
    });
    response = await services.post(
      `${constantURL.complaint}/${complaintId}/user`,
      { users: mappedUsers }
    );
  } catch (error) {
    if (!users || users.length === 0) return 400;
    return error.response.status;
  }
  return response.status;
};

// Every time you change complaint's status. Close, reopen, assign, solved.
export const changeStatus = async ({ complaintId, status }) => {
  let response;

  try {
    response = await services.post(`${constantURL.complaint}/${complaintId}`, {
      statusId: status,
    });
  } catch (error) {
    return error.response.status;
  }
  return response.status;
};

export const createComplaint = async ({ request }) => {
  let response;

  let payload = new FormData();
  payload.append(
    "complaintString",
    JSON.stringify({ ...request, country: request.country.id })
  );
  if (!!request.documents && request.documents.length > 0)
    request.documents.forEach((el) => payload.append("files", el));

  try {
    response = await services.post(constantURL.complaint, payload);
    store.dispatch(`Setting/setComplaint`, response.data);
  } catch (error) {
    return error.response;
  }
  return response; // Don't retrieve the status because we need the complaint detail as well
};

export const downloadFile = async ({ complaintId, file }) => {
  var blob = {};

  let anonymous = _checkAnonymity();

  try {
    var response = await services.get(
      `${anonymous}/${constantURL.complaint}/${complaintId}/document/${file.id}`
    );
    blob = new Blob([new Uint8Array(response.data.Body.data)], {
      type: "application/octet-stream",
    });
  } catch (err) {}

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", file.name); //or any other extension
  document.body.appendChild(link);
  link.click();
};

export const getAllComplaints = async ({ status, limit, offset, filter }) => {
  const { t } = i18n.global;
  let response;
  let byStatus = !!status
    ? status.map((stat) => `&status[]=${stat}`).join("")
    : "";
  let isMobile = computed(() => store.getters[`Setting/mobileState`]);
  let filters = !!filter ? filter : "";

  try {
    response = await services.get(
      `${constantURL.complaint}?offset=${offset}&limit=${limit}` +
        byStatus +
        filters
    );
  } catch (error) {
    return error.response;
  }
  if (response.data.content.length > 0) {
    const currentTimezone = moment.tz.guess();
    response.data.content.forEach((el) => {
      if (isMobile.value) {
        el.createdAt = moment.utc(el.createdAt).tz(currentTimezone)
          .format("DD/MM/YY HH:mm");
        el.lastUpdatedAt = moment.utc(el.lastUpdatedAt).tz(currentTimezone)
          .format("DD/MM/YYYY HH:mm");
      } else {
        el.createdAt =  moment.utc(el.createdAt).tz(currentTimezone)
          .format('DD/MM/YY HH:mm');
        el.lastUpdatedAt = moment.utc(el.lastUpdatedAt).tz(currentTimezone)
          .format("DD/MM/YYYY HH:mm");
      }
      el.type = t(`app.complaintTypes[${el.typeId}].type`);
      el.statusDescription = t(`app.status[${el.statusId}]`);
      if (el.users) {
        el["manager"] = el.users.map((item) => {
          return `${item.email}`;
        });
      }
    });
  }
  await store.dispatch(`Setting/setComplaints`, {
    filter: status
      ? status[0] === "1"
        ? "notAssignedComplaints"
        : "assignedComplaints"
      : "complaints",
    totalElementFilter: status
      ? status[0] === "1"
        ? "totalElementsNotAssigned"
        : "totalElementsAssigned"
      : "totalElements",
    data: response.data.content,
    totalElements: response.data.totalElements,
  });
};

export const getComplaintById = async ({ complaintId, password }) => {
  const { t } = i18n.global;
  let response;
  let byId = !!complaintId ? `/${complaintId}` : "";
  let anonymous = !!password ? `/anonymous` : "";
  try {
    response = await services.get(
      `${anonymous}/${constantURL.complaint}` + byId
    );
    response.data.type = t(`app.complaintTypes[${response.data.typeId}].type`);
    store.dispatch(`Setting/setComplaint`, response.data);
    return response.status;
  } catch (error) {
    return error.response.status;
  }
};

export const getKPIs = async () => {
  let response;
  try {
    response = await services.get(constantURL.kpi);
  } catch (error) {
    return error.response;
  }
  return response.data;
};

export const getAllMessages = async ({ complaintId }) => {
  let response;
  let anonymous = _checkAnonymity();

  try {
    response = await services.get(
      `${anonymous}/${constantURL.complaint}/${complaintId}/message`
    );
    for (let prop in response.data[0]) {
      response.data[0][prop].forEach((message) => {
        message["me"] =
          (message.informer && anonymous) || (!message.informer && !anonymous)
            ? true
            : false;
      });
    }
    await store.dispatch(`Setting/setMessages`, response.data[0]);
  } catch (error) {
    return error.response.status;
  }
};

export const resetComplaintPassword = async ({ id, password }) => {
  let response;

  try {
    response = await services.post(
      `/anonymous/${constantURL.complaint}/${id}`,
      { password: password }
    );
  } catch (error) {
    return error.response.status;
  }
  return response.status;
};

export const sendMessage = async ({ complaintId, message }) => {
  let anonymous = _checkAnonymity();
  try {
    await services.post(
      `${anonymous}/${constantURL.complaint}/${complaintId}/message`,
      { text: message }
    );
    await getAllMessages({ complaintId });
  } catch (error) {
    return error.response;
  }
};
export const updateAttachments = async ({ complaintId, documents }) => {
  let response;
  let payload = new FormData();
  if (!!documents && documents.length > 0)
    documents.forEach((el) => {
      if (el instanceof File && !el.id) payload.append("files", el);
    });

  try {
    response = await services.post(
      `anonymous/${constantURL.complaint}/${complaintId}/document`,
      payload
    );
    await store.dispatch("Setting/setComplaint", response.data);
    return response.status;
  } catch (error) {
    return error.response.status;
  }
};

const _checkAnonymity = () => {
  let user = JSON.parse(sessionStorage.getItem("userConnected"));
  return user && user.role ? "" : "anonymous";
};
